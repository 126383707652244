.App {
  text-align: center;
}

.App-logo {
  height: 8vw;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: rgb(57, 56, 56);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: rgb(0, 0, 0);
}

.App-link {
  color: #61dafb;
}

a {
  transition-duration: .4s;
  color: #393838;
  text-decoration: none;
}

a:hover {
  transition-duration: .4s;
  color: #00AFB7;
  /* font-size: calc(5px + 2vmin); */
  padding: 0px;
}

@media (max-width: 767px) {
  .App-logo {
    height: 18vw;
    pointer-events: none;
  }
}