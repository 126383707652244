.homeContainer {
  height: 100%;
  display: flex;
  justify-content: center;
  color: white;
  flex-direction: column;
  margin-bottom: 2rem;
}

.portContainer {
  height: 100vh;
  display: flex;
  justify-content: center;
  color: white;
  flex-direction: column;
  margin-bottom: 2rem;
}

.specialText {
  color: #0b949a;
}

.headingText {
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 0.5rem;
}

.firstBox {
  border: solid 2px white;
  margin-left: 10%;
  margin-right: 10%;
  padding: 20px;
}

/* Home */
.iconBoxContainer1 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 2rem;
  margin-top: 0rem;
  /* border: solid 2px white; */
  /* margin-left: 10%;
    margin-right: 10%; */
  /* padding: 20px; */
}

.iconBoxContainer2 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 0rem;
  /* border: solid 2px white; */
  /* margin-left: 10%;
    margin-right: 10%; */
  /* padding: 20px; */
}

.iconBox {
  border: solid 2px white;
  margin-left: 2%;
  margin-right: 2%;
  padding: 20px;
  /* margin: auto; */
  max-width: 10rem;
}

/* Pricing */
.PricingiconBoxContainer1 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 1rem;
  /* border: solid 2px white; */
  /* margin-left: 10%;
    margin-right: 10%; */
  /* padding: 20px; */
}

.PricingiconBox {
  border: solid 2px white;
  margin-left: 2%;
  margin-right: 2%;
  padding: 20px;
  /* margin: auto; */
  max-width: 10rem;
  min-width: 10rem;
}

.pricingBox {
  margin: 10px;
}

.pricesBottom {
  border: solid 2px rgb(11, 148, 154);
  margin-top: 10px;
  font-size: 10px;
  padding-bottom: 10px;
  margin-left: 10px;
}

.Pricingicons {
  font-size: 30px;
  margin-bottom: 0px;
}

.icons {
  font-size: 30px;
  padding-top: 15px;
}

.consultationButton {
  transition-duration: 0.3s;
  margin: 10px;
  font-size: 30px;
  padding: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 5px;
  color: rgb(57, 56, 56);
  background-color: rgb(11, 148, 154);
  margin-bottom: 0px;
}

.consultationButton:hover {
  transition-duration: 0.3s;
  background-color: white;
  color: rgb(57, 56, 56);
}

.carouselText {
  font-size: 10px;
  padding-bottom: 40px;
}

.footerContainer {
  display: flex;
  background-color: rgb(11, 148, 154);
  /* position: fixed; */
  bottom: 0;
  width: 100%;
  font-size: 12px;
  justify-content: right;
  font-weight: bold;
  justify-content: space-between;
  color: rgb(57, 56, 56);
  padding-top: 2px;
}

.projectsDiv {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: 5px;
  max-width: 60%;
  margin: auto;
  margin-top: 30px;
}

.projectsDiv h4 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-left: 5px;
  margin-right: 5px;
  margin-top: 5px;
  margin-bottom: 5px;
  font-size: 1vw;
  font-weight: bold;
}

.projectsDiv img {
  /* margin-right: 5px; */
  max-width: 100px;
  border-radius: 10px;
  margin: 10px;
}

@media (max-width: 767px) {
  .portContainer {
    height: 100%;
    display: flex;
    justify-content: center;
    color: white;
    flex-direction: column;
    margin-bottom: 2rem;
  }
  .projectsDiv {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    margin-bottom: 5px;
    max-width: 60%;
    margin: auto;
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .iconBoxContainer1 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: auto;
    /* border: solid 2px white; */
    /* margin-left: 10%;
        margin-right: 10%; */
    /* padding: 20px; */
  }

  .projectsDiv img {
    margin-right: 5px;
    max-width: 150px;
    border-radius: 5px;
  }

  .projectsDiv {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    margin-bottom: 5px;
    max-width: 60%;
    margin: auto;
    margin-top: 0px;
    margin-bottom: 0px;
    height: 100%;
  }

  .iconBoxContainer2 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: auto;
    /* border: solid 2px white; */
    /* margin-left: 10%;
        margin-right: 10%; */
    /* padding: 20px; */
  }

  .iconBox {
    border: solid 2px white;
    margin-left: 10%;
    margin-right: 10%;
    padding: 20px;
    margin: auto;
    max-width: 10rem;
    margin-bottom: 20px;
  }

  .PricingiconBoxContainer1 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: auto;
    /* border: solid 2px white; */
    /* margin-left: 10%;
        margin-right: 10%; */
    /* padding: 20px; */
  }

  .PricingiconBox {
    border: solid 2px white;
    margin-left: 2%;
    margin-right: 2%;
    padding: 20px;
    /* margin: auto; */
    max-width: 10rem;
    min-width: 10rem;
    margin: 20px;
    margin-bottom: 0px;
  }

  .pricesBottom {
    border: solid 2px rgb(11, 148, 154);
    margin-top: 10px;
    font-size: 10px;
    padding-bottom: 10px;
    margin-left: 0px;
  }
}
