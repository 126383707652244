.container {
    display: flex;
    flex-direction: column;
}

.consultationText {
    justify-content: left;
    justify-self: left;
    align-self: left;
    font-weight: bold;
}

.input {
    padding-left: 20px;
    padding-right:20px;
    font-weight: bold;
    text-align: center;
}

.formContainer {
    justify-content: left;
    justify-items: left;
    align-items: left;
    align-content: left;
}

.description {
    height: 200px;
    width: 70%;
    word-wrap: break-word;
    word-break: normal;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}