.navbar {
    background-color: white;
    padding: 10px;
    padding-left: 20px;
    padding-right: 20px;
    display: flex;
    justify-content: space-between;
    font-weight: bold;
}

.navbarImage {
    height: 2.5rem;
}

.navbarItems {
    word-spacing: 1em;
    padding-top: 8px;
    font-weight: bold;
    color: rgb(57, 56, 56);
}

button {
    transition-duration: .4s;
    border: none;
    background-color: white;
    font-weight: bold;
    color:rgb(57, 56, 56);
}

button:hover {
    transition-duration: .4s;
    color: #00AFB7;
}

@media (max-width: 767px) {
    .navbar {
        background-color: rgb(255, 255, 255);
    }

    .navbarImage {
        height: 1.5rem;
    }

    .navbarItems {
        word-spacing: 1em;
        padding-top: 3px;
        font-weight: bold;
        color: rgb(57, 56, 56);
    }

    button {
        border: none;
        background-color: white;
        font-weight: bold;
        color:rgb(57, 56, 56);
    }

    button:hover {
        transition-duration: .4s;
        color: #00AFB7;
    }
}